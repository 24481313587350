<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-row>
        <v-col class="mb-0 pb-0" cols="12" sm="12" md="12" lg="12" fill-height>
          <v-card elevation="10" tile>
            <v-list
              ><v-list-item>
                <v-list-item-title class="headline">{{
                  dashboardName
                }}</v-list-item-title>
                <v-list-action>
                  <v-btn icon @click="fullScreen">
                    <v-icon color="primary">mdi-fullscreen</v-icon>
                  </v-btn>
                </v-list-action>
                <v-list-action>
                  <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
            </v-list>
            <div
              id="dashboardContainer"
              style="width: 100%; height: 75vh"
            ></div>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      dialog: false,
      dashboardName: null,
      q_and_a: this.$store.getters.isQna,
      pbitoken: null,
      size: null,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
    };
  },
  created: function () {
    this.dashboardName = this.$route.query.dashboard_name;

    apiService
      .getPBITokenDashboard(this.$route.query.dashboard_id)
      .then((response) => {
        var models = window["powerbi-client"].models;
        var embedConfiguration = {
          type: "dashboard",
          id: this.$route.query.dashboard_id,
          embedUrl:
            "https://app.powerbi.com/dashboardEmbed?/groups/d3773f73-ce4d-4af0-8ec4-bde0c3126f95/dashboards/" +
            this.$route.query.dashboard_id,
          tokenType: models.TokenType.Embed,
          accessToken: response.data.token,
          viewMode: models.ViewMode.View,
          pageView: "fitToWidth",
        };

        var $dashboardContainer = $("#dashboardContainer");
        var dashboard = powerbi.embed(
          $dashboardContainer.get(0),
          embedConfiguration
        );
        this.successMessage = "Succesfully Found Dashboard";
        this.hasSuccess = true;
        return dashboard;
      });

    analytics.page("Dashboard", {
      "Dashboard-Name": this.$route.query.dashboard_name,
    });
  },
  mounted() {},
  updated() {
    var navigate = this.$router;
    var element = $("#dashboardContainer")[0];
    var dashboard = powerbi.get(element);
    var clickName = this.dashboardName;

    dashboard.on("tileClicked", function (event) {
      var data = event.detail.reportEmbedUrl.slice(45, 81);

      if (data === "") {
        return;
      } else {
        navigate.push(
          "/Report?report_id=" +
            event.detail.reportEmbedUrl.slice(45, 81) +
            "&report_name=" +
            clickName
        );
        analytics.track("Dashboard Clickthrough", {
          "Dashboard-Name": this.$route.query.dashboard_name,
        });
      }
    });
  },
  methods: {
    fullScreen() {
      var element = $("#dashboardContainer")[0];
      var dashboard = powerbi.get(element);
      dashboard.fullscreen();
      analytics.track("Dashboard Fullscreen", {
        "Dashboard-Name": this.$route.query.dashboard_name,
      });
    },
    close() {
      analytics.track("Dashboard Closed", {
        "Dashboard-Name": this.$route.query.dashboard_name,
      });
      this.$router.push({ path: "/Dashboards" });
    },
  },
};
</script>

<style>
#reportContainer,
.reportContainer {
  background-color: transparent;
}
.desktop-view iframe,
.mobile-view iframe,
iframe {
  border: none;
  background-color: transparent;
}
</style>
