<template>
<v-container fluid>
  <v-card>
    <v-card-title>Exports</v-card-title>
    <v-card-subtitle class="overline">PDF Exports of your reports, exports are available to download for 24hrs</v-card-subtitle>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Requested At
            </th>
            <th class="text-left">
              Expires At
            </th>
            <th class="text-left">
              Status
            </th>
            <th class="text-center">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in exports" :key="item.name">
            <td>{{ item.export_name }}</td>
            <td>{{ new Date(item.created_at).toLocaleString() }}</td>
            <td><span v-if="item.expires_at != null">{{ new Date(item.expires_at).toLocaleString() }}</span></td>
            <td>
              <span v-if="item.status == 'Succeeded' && item.retries != 3" class="success--text">Complete</span>
              <span v-if="item.retries != 3 && item.status != 'Succeeded'" class="primary--text">Pending</span>
              <span v-if="item.retries == 3" class="error--text">Error - An Admin Has Been Notified</span>
            </td>

            <td class="text-center">
              <v-btn v-if="item.status == 'Succeeded'" small @click="downloadExport(item.download_url, item.export_name)">Download
                <v-icon right dark>
                  mdi-download
                </v-icon>
              </v-btn>
              <v-progress-circular v-if="item.retries != 3 && item.status != 'Succeeded'" indeterminate color="primary"></v-progress-circular>
              <v-icon  color="error" v-if="item.retries == 3">
                mdi-close
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</v-container>
</template>

<script>
import apiService from "../services/services.js";

export default {
  data() {
    return {
      exports: null
    }
  },
  created(){
    apiService.getExports(this.$store.getters.getUserId)
      .then(response => {
        this.exports = response.data.data
      })
      .catch(err => {
        console.log(err)
      })

      setInterval(this.refresh, 30000);
  },
  methods: {
    refresh(){
      apiService.getExports(this.$store.getters.getUserId)
        .then(response => {
          this.exports = response.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    downloadExport(url, name){
      apiService.downloadExport(url)
        .then(response => {

          const url = window.URL.createObjectURL(new Blob([response.data], {
            type: 'application/pdf',
          }));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
