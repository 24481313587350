import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    isAuth: false,
    isAdmin: false,
    isMaster: false,
    isQna: false,
    token: null,
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
    companyId: null,
    companyName: null,
    companyDesc: null,
    color: '#787878',
    logo: null,
    companyEmail: null,
    canExport: false,
  },
  mutations: {
    logIn(state, userData) {
      state.token = userData.token
      state.userId = userData.user.id
      state.isQna = userData.user.q_and_a
      state.firstName = userData.user.first_name
      state.lastName = userData.user.last_name
      state.email = userData.user.email
      state.isAuth = true
      state.companyId = userData.user.company_id
      state.companyName = userData.user.company_name
      state.companyDesc = userData.user.company_description
      state.color = userData.user.primiary_color
      state.logo = userData.user.company_logo
      state.isAdmin = userData.user.is_admin
      state.isMaster = userData.user.is_master
      state.companyEmail = userData.user.company_email
      state.canExport = userData.user.can_export_data
    },
    logOut(state) {
      state.token = null
      state.userId = null
      state.isQna = null
      state.firstName = null
      state.lastName = null
      state.email = null
      state.isAuth = false
      state.companyId = null
      state.companyName = null
      state.companyDesc = null
      state.color = null
      state.logo = null
      state.isAdmin = null
      state.isMaster = null
      state.companyEmail = null
      state.canExport = null
    },
    editEmail(state, email) {
      state.email = email
    }
  },
  getters: {
    isAuth: state => state.isAuth,
    isAdmin: state => state.isAdmin,
    isMaster: state => state.isMaster,
    isQna: state => state.isQna,
    getFirstName: state => state.firstName,
    getLastName: state => state.lastName,
    getEmail: state => state.email,
    getToken: state => state.token,
    getColor: state => state.color,
    getCompany: state => state.companyName,
    getLogo: state => state.logo,
    getCompanyId: state => state.companyId,
    getCompanyDesc: state => state.companyDesc,
    getUserId: state => state.userId,
    getCompanyEmail: state => state.companyEmail,
    getExportAccess: state => state.canExport,
  }
})