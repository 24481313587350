<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3" fill-height>
        <v-card class="mx-auto">
          <v-card-title>
            <v-icon color="primary" class="mr-5" size="64">
              mdi-account
            </v-icon>
            <v-row align="start">
              <div>
                <span class="display-2">{{ users.length }}</span>
                <strong class="overline"> Total Users</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>

            <v-btn icon class="align-self-start" size="28">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <v-btn
              icon
              class="align-self-start"
              size="28"
              router
              to="/Add-User-Master"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Selected User</v-card-text
          >
          <br />
          <center>
            <v-avatar size="60" color="primary">
              <span class="white--text heading">{{
                users[selected].initials
              }}</span>
            </v-avatar>
          </center>
          <v-card-text class="headline text-center">{{
            users[selected].name
          }}</v-card-text>
          <v-card-text class="primary--text mt-n8 text-center">{{
            users[selected].email
          }}</v-card-text>
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Created At: {{ users[selected].createdAt }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Reports: {{ users[selected].reports }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Dashboards: {{ users[selected].dashboards }}</v-card-text
          >
          <v-row justify="center"
            ><v-btn icon size="50">
              <v-icon>mdi-tune</v-icon>
            </v-btn>
            <v-btn icon size="50">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="error"
                  size="50"
                  @click="deleteUser"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Delete User</span>
            </v-tooltip>
          </v-row>
          <br />
          <v-divider></v-divider>
          <v-col align-content-center>
            <v-card-text class="grey--text mt-n4 text-center overline"
              >Selected User Settings</v-card-text
            >
            <v-switch
              v-model="users[selected].is_admin"
              inset
              label="Admin Access"
              @click="editAdminAccess"
            ></v-switch>
            <v-switch
              v-model="users[selected].qna"
              inset
              label="Dataset Access"
              @click="editQnaAccess"
            ></v-switch>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="3" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title"
                >User Directory</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item-group v-model="selected" color="primary">
              <v-list-item v-for="user in users" :key="user.id" two-line>
                <v-list-item-avatar color="primary">
                  <span class="white--text overline">{{ user.initials }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ user.user_id }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="shareReport(user)">
                    <v-icon color="primary">mdi-share</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn icon @click="shareReport(user)">
                    <v-icon color="primary">mdi-share</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="3" fill-height>
        <v-card class="mx-auto">
          <v-card-title>
            <v-icon color="primary" class="mr-5" size="64">
              mdi-factory
            </v-icon>
            <v-row align="start">
              <div>
                <span class="display-2">{{ companys.length }}</span>
                <strong class="overline"> Companys</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>

            <v-btn icon class="align-self-start" size="28">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <v-btn
              icon
              class="align-self-start"
              size="28"
              router
              to="/Add-Company-Master"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Selected Company</v-card-text
          >
          <br />
          <center>
            <v-img
              class="align-center"
              width="66%"
              contain
              :src="this.companys[selectedCompany].logo"
            >
            </v-img>
          </center>
          <v-card-text class="headline text-center">{{
            companys[selectedCompany].name
          }}</v-card-text>
          <v-card-text class="primary--text mt-n8 text-center">{{
            companys[selectedCompany].description
          }}</v-card-text>
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Created At: {{ companys[selectedCompany].createdAt }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Colour: {{ companys[selectedCompany].colour }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Domain: {{ companys[selectedCompany].domain }}</v-card-text
          >
          <v-row justify="center"
            ><v-btn icon size="50">
              <v-icon>mdi-tune</v-icon>
            </v-btn>
            <v-btn icon size="50">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="error"
                  size="50"
                  @click="deleteUser"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Delete Company</span>
            </v-tooltip>
          </v-row>
          <br />
          <v-divider></v-divider>
          <v-col align-content-center>
            <v-card-text class="grey--text mt-n4 text-center overline"
              >Selected Company Settings</v-card-text
            >
            <v-switch
              v-model="companys[selectedCompany].suspended"
              inset
              label="Suspended"
              @click="editAdminAccess"
            ></v-switch>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="3" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title"
                >Company Directory</v-list-item-title
              >
              <v-btn icon class="align-self-start" size="28" @click="pullData">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-model="selectedCompany" color="primary">
              <v-list-item
                v-for="company in companys"
                :key="company.id"
                two-line
              >
                <v-list-item-avatar color="primary">
                  <span class="white--text overline">{{
                    company.initials
                  }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ company.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ company.id }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="shareReport(user)">
                    <v-icon color="primary">mdi-share</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn icon @click="shareReport(user)">
                    <v-icon color="primary">mdi-share</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";

export default {
  data: () => ({
    users: [],
    dates: [],
    selected: [0],
    selectedCompany: [0],
    hasError: false,
    errorMessage: null,
    hasSuccess: false,
    successMessage: null,
    companys: [],
  }),

  created() {
    apiService.getAllUsers().then((response) => {
      var usercount = response.data.data.length;
      this.users = [];
      if (usercount > 0) {
        response.data.data.map((userData, index) => {
          this.users.push({
            name: userData.first_name + " " + userData.last_name,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            is_admin: userData.is_admin,
            user_id: userData.id,
            qna: userData.q_and_a,
            createdAt:
              new Date(userData.created_at).getDate() +
              "/" +
              (new Date(userData.created_at).getMonth() + 1) +
              "/" +
              new Date(userData.created_at).getFullYear(),
            reports: userData.report,
            datasets: userData.dataset,
            dashboards: userData.dashboard,
            initials:
              userData.first_name.substring(0, 1) +
              userData.last_name.substring(0, 1),
          });
        });
      }
      this.dates = [];
      if (usercount > 0) {
        response.data.data.map((userData, index) => {
          this.dates.push({
            createdAt: userData.created_at,
            value: 1,
          });
        });
      }
    });

    apiService.getAllCompany().then((response) => {
      var companyCount = response.data.data.length;
      this.companys = [];
      if (companyCount > 0) {
        response.data.data.map((companyData, index) => {
          this.companys.push({
            name: companyData.company_name,
            id: companyData.id,
            description: companyData.company_description,
            colour: companyData.primiary_color,
            domain: companyData.subdomain_url,
            logo: companyData.company_logo,
            createdAt:
              new Date(companyData.created_at).getDate() +
              "/" +
              (new Date(companyData.created_at).getMonth() + 1) +
              "/" +
              new Date(companyData.created_at).getFullYear(),
            initials: companyData.company_name.substring(0, 1),
            suspended: companyData.is_suspended,
          });
        });
      }
    });

    analytics.page("Admin Master");
  },
  computed: {
    labels() {
      return ["Datasets", "Reports", "Dashboards"];
    },
    items() {
      return [
        this.users[this.selected].datasets.length,
        this.users[this.selected].reports.length,
        this.users[this.selected].dashboards.length,
      ];
    },
  },

  watch: {},

  methods: {
    pullData() {
      apiService
        .refreshReports()
        .then((response) => {
          this.successMessage = "Succesfully Refreshed Reports";
          this.hasSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });

      apiService
        .refreshDashboards()
        .then((response) => {
          this.successMessage = "Succesfully Refreshed Dashboards";
          this.hasSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });

      apiService
        .refreshDatasets()
        .then((response) => {
          this.successMessage = "Succesfully Refreshed Datasets";
          this.hasSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
      analytics.track("Power BI Sync");
    },
    deleteUser() {
      confirm("Are you sure you want to delete this user?");

      apiService
        .deleteUser(this.users[this.selected].user_id)
        .then((response) => {
          this.successMessage = "Succesfully Deleted User";
          this.hasSuccess = true;
          this.refreshUsers();
          analytics.track("Delete User");
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    editAdminAccess() {

      if (confirm("Are you sure you want to edit user access permissions?")) {
      apiService
        .editUser(
          this.users[this.selected].last_name,
          this.users[this.selected].first_name,
          this.users[this.selected].email,
          this.users[this.selected].user_id,
          this.users[this.selected].is_admin,
          this.users[this.selected].qna
        )
        .then((response) => {
          this.successMessage = "Succesfully edited admin permissions for user";
          this.hasSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
      } else {
          setTimeout(this.flipAdmin, 1)
        }
    },
    editQnaAccess() {

      if (confirm("Are you sure you want to edit user Q&A permissions?")) {
        apiService
          .editUser(
            this.users[this.selected].last_name,
            this.users[this.selected].first_name,
            this.users[this.selected].email,
            this.users[this.selected].user_id,
            this.users[this.selected].is_admin,
            this.users[this.selected].qna
          )
          .then((response) => {
            this.successMessage = "Succesfully edited Q&A permissions for user";
            this.hasSuccess = true;
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error;
            this.hasError = true;
          });
        } else {
          setTimeout(this.flipQnA, 1)
        }

    },
    flipQnA(){
      this.users[this.selected].qna = !this.users[this.selected].qna;
    },
    flipAdmin(){
      this.users[this.selected].is_admin = !this.users[this.selected].is_admin;
    },
    refreshUsers() {
      this.users = [];
      apiService
        .getUserCompany(this.$store.getters.getCompanyId)
        .then((response) => {
          var usercount = response.data.data.length;
          this.users = [];
          if (usercount > 0) {
            response.data.data.map((userData, index) => {
              this.users.push({
                name: userData.first_name + " " + userData.last_name,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                is_admin: userData.is_admin,
                user_id: userData.id,
                qna: userData.q_and_a,
                createdAt:
                  new Date(userData.created_at).getDate() +
                  "/" +
                  (new Date(userData.created_at).getMonth() + 1) +
                  "/" +
                  new Date(userData.created_at).getFullYear(),
                reports: userData.report,
                datasets: userData.dataset,
                dashboards: userData.dashboard,
                initials:
                  userData.first_name.substring(0, 1) +
                  userData.last_name.substring(0, 1),
              });
            });
          }
          this.dates = [];
          if (usercount > 0) {
            response.data.data.map((userData, index) => {
              this.dates.push({
                createdAt: userData.createdAt,
                value: 1,
              });
            });
          }
        });
    },
  },
};
</script>
