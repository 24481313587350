<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="12" md="4">
      <v-card class="overflow-hidden">
        <v-toolbar flat color="primary">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light ml-5">
            Login
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form v-model="valid">
          <v-card-text>
            <v-card-text class="overline ml-n3"
              >Forgot Your Password?
              <a @click="forgotPassword">Reset It Here</a></v-card-text
            >
            <v-text-field
              v-model="auth.email"
              color="primary"
              label="Email"
              type="email"
              :rules="emailRules"
              outlined
              prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              v-model="auth.password"
              color="primary"
              label="Password"
              type="password"
              outlined
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" outlined rounded @click="userLogin">
              Login
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="hasError"
          :timeout="3000"
          bottom
          color="error"
          right
        >
          Error: {{ errorMessage }}
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      auth: {
        email: "",
        password: "",
      },
      hasError: false,
      errorMessage: null,
      emailRules: [(v) => /.+@.+/.test(v) || "Please use valid E-mail format"],
    };
  },
  created() {
    analytics.page("Login");
  },
  methods: {
    userLogin() {
      apiService
        .login(this.auth.email.toLowerCase(), this.auth.password)
        .then((response) => {

          this.$store.commit("logIn", {'user': response.data.success,'token':response.data.token});
          this.$router.push({ path: "/" });
          location.reload(true)
          analytics.track("Login");
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    forgotPassword() {
      this.$router.push({ path: "/Forgot-Password" });
    },
  },
};
</script>
