<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" fill-height>
        <v-card class="mx-auto">
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">Add A User</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-stepper v-model="e6" vertical flat>
            <v-stepper-step editable :complete="e6 > 1" step="1">
              Name
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  placeholder="Add First Name Here"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  placeholder="Add Last Name Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn color="primary" @click="e6 = 2"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 2" step="2">
              Email
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="email"
                  label="Email"
                  placeholder="Add First Name Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn color="primary" @click="e6 = 3"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 3" step="3">
              Company
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedCompany"
                  :items="companys"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  outlined
                ></v-select>
              </v-col>
            </v-stepper-content>
            <v-stepper-step editable :complete="e6 > 4" step="4">
              Access Permissions
            </v-stepper-step>

            <v-stepper-content step="4">
              <v-checkbox
                class="pl-5"
                v-model="isAdmin"
                label="Admin User"
              ></v-checkbox>
              <v-checkbox
                class="pl-5"
                v-model="qnaAccess"
                label="Q&A Access"
              ></v-checkbox>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn color="primary" @click="e6 = 5"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable step="5"> Review & Submit </v-stepper-step>
            <v-stepper-content step="5">
              <v-card-text class="overline pb-1"
                >First Name: {{ firstName }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Last Name: {{ lastName }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Email: {{ email }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >CompanyId: {{ selectedCompany }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Company Admin:
                <v-btn
                  disabled
                  v-if="isAdmin"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  disabled
                  v-if="!isAdmin"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-text class="overline pb-1"
                >Q&A Access:
                <v-btn
                  disabled
                  v-if="qnaAccess"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  disabled
                  v-if="!qnaAccess"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn color="primary" @click="addUser"> Submit </v-btn>
              </v-col>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      e6: 1,
      firstName: null,
      lastName: null,
      email: null,
      isAdmin: false,
      qnaAccess: false,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      companys: [],
      selectedCompany: [],
    };
  },
  created() {
    apiService.getAllCompany().then((response) => {
      var companyCount = response.data.data.length;
      this.companys = [];
      if (companyCount > 0) {
        response.data.data.map((companyData, index) => {
          this.companys.push({
            name: companyData.company_name,
            id: companyData.id,
            description: companyData.company_description,
            colour: companyData.primiary_color,
            domain: companyData.subdomain_url,
            logo: companyData.company_logo,
            createdAt:
              new Date(companyData.createdAt).getDate() +
              "/" +
              (new Date(companyData.createdAt).getMonth() + 1) +
              "/" +
              new Date(companyData.createdAt).getFullYear(),
          });
        });
      }
    });

    analytics.page("Add User Master");
  },
  methods: {
    addUser() {
      apiService
        .addUser(
          this.firstName,
          this.lastName,
          this.email,
          this.selectedCompany,
          this.isAdmin,
          this.qnaAccess
        )
        .then((response) => {
          analytics.track("Add User Master");
          this.$router.push({ path: "/Master-Admin" });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
  },
};
</script>
