<template>
<v-container fluid>
  <v-row>
    <v-col sm="12" md="4" lg="4">
      <v-card :elevation="hover ? 12 : 2">
        <center>
          <v-img :src="this.$store.getters.getLogo" class="pt-15 pb-15 align-center" contain width="85%" max-height="200" />
        </center>
      </v-card>
    </v-col>

    <v-col sm="12" md="4" lg="4">
      <v-card>
        <v-card-title>What Are Dashboards Used For?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>Dashboards are high level overviews of the most important
          information.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="dashboardCount == 0" color="primary" rounded router to="/Dashboards">
            View Your Dashboards
            <v-icon right dark> mdi-open-in-new </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col sm="12" md="4" lg="4">
      <v-card>
        <v-card-title>What Are Reports Used For?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>Reports are drilled down analytics designed to be investigated in
          detail.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded router to="/Reports">
            View Your Reports
            <v-icon right dark> mdi-open-in-new </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" md="4" lg="4">
      <v-card>
        <v-card-title>Recently Shared With You</v-card-title>
        <v-divider></v-divider>

        <v-list>
          <v-list-item-group  color="primary">
            <v-list-item v-for="(item, i) in recents" :key="i" link :to="item.type + '/?' + item.type.toLowerCase() + '_id=' + item.pbi_id + '&' + item.type.toLowerCase() + '_name=' + item.name + '&dataset_id='+ item.pbi_dataset">
              <v-list-item-icon>
                <v-icon v-show="item.type === 'Report'">mdi-chart-line</v-icon>
                <v-icon v-show="item.type === 'Dashboard'">mdi-television-guide</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle class="overline pt-1">Click to open {{item.type}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-card>
    </v-col>
    <v-col sm="12" md="8" lg="8">
      <v-card>
        <v-card-title>Account Stats</v-card-title>
        <v-divider></v-divider>
        <v-card-text>Your Analytics Assets</v-card-text>
        <v-col>
          <center>
            <v-progress-circular :rotate="-90" :size="175" :width="20" :value="allAssetCount" color="primary">
              <v-card-text class="headline mb-n5">{{ reports.length + dashboards.length }}
                <v-card-text class="overline mt-n5">All Assets</v-card-text>
              </v-card-text>
            </v-progress-circular>
            <v-progress-circular :rotate="-90" :size="175" :width="20" :value="dashboardCount" color="primary" class="ml-15">
              <v-card-text class="headline mb-n5">{{ dashboards.length }}
                <v-card-text class="overline mt-n5">Dashboards</v-card-text>
              </v-card-text>
            </v-progress-circular>
            <v-progress-circular :rotate="-90" :size="175" :width="20" :value="reportCount" color="primary" class="ml-15">
              <v-card-text class="headline mb-n5">{{ reports.length }}
                <v-card-text class="overline mt-n5">Reports</v-card-text>
              </v-card-text>
            </v-progress-circular>
          </center>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
  <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
    Error: {{ errorMessage }}
  </v-snackbar>
  <v-snackbar v-model="hasSuccess" :timeout="3000" bottom color="success" right>
    Success: {{ successMessage }}
  </v-snackbar>
</v-container>
</template>

<script>
import apiService from "../services/services.js";

export default {
  name: "Home",
  data() {
    return {
      reports: [],
      dashbords: [],
      recents: [],
      intervalOne: {},
      intervalTwo: {},
      intervalThree: {},
      reportCount: 0,
      dashboardCount: 0,
      allAssetCount: 0,
      companyDesc: this.$store.getters.getCompanyDesc,
    };
  },
  created() {

    apiService
      .getRecent(this.$store.getters.getUserId)
      .then(res => {
        console.log(res.data)
        this.recents = res.data;
      })
      .catch((error) => {
        console.log(error)
        this.errorMessage = 'Could not find recent assets';
        this.hasError = true;
      });

    apiService
      .getPBIReports()
      .then((response) => {
        var reportcount = response.data.data.length;
        this.reports = [];
        if (reportcount > 0) {
          response.data.data.map((reportData, index) => {
            this.reports.push({
              name: reportData.report_name,
              id: reportData.report_power_bi_id,
              dataset_id: reportData.dataset_id,
              system_id: reportData.id,
              created: new Date(reportData.createdAt).getDate() +
                "/" +
                (new Date(reportData.createdAt).getMonth() + 1) +
                "/" +
                new Date(reportData.createdAt).getFullYear(),
            });
            this.successMessage = "Succesfully Found Reports";
            this.hasSuccess = true;
            return index;
          });
        }
      })
      .catch((error) => {
        this.errorMessage = error.response.data.error;
        this.hasError = true;
      });

    apiService
      .getPBIDashboards()
      .then((response) => {
        var dashboardcount = response.data.data.length;
        this.dashboards = [];
        if (dashboardcount > 0) {
          response.data.data.map((dashboardData, index) => {
            this.dashboards.push({
              name: dashboardData.dashboard_name,
              id: dashboardData.dashboard_power_bi_id,
              system_id: dashboardData.id,
              created: new Date(dashboardData.createdAt).getDate() +
                "/" +
                (new Date(dashboardData.createdAt).getMonth() + 1) +
                "/" +
                new Date(dashboardData.createdAt).getFullYear(),
            });
            this.successMessage = "Succesfully Found Dashboards";
            this.hasSuccess = true;
            return index;
          });
        }
      })
      .catch((error) => {
        this.errorMessage = error.response.data.error;
        this.hasError = true;
      });



    analytics.page("Home");
    var analyticsUser = this.state.getters.getUserId;
    var analyticsCompany = this.state.getters.getCompanyId;
  },
  beforeDestroy() {
    clearInterval(this.intervalOne);
    clearInterval(this.intervalTwo);
    clearInterval(this.intervalThree);
  },
  mounted() {
    this.intervalOne = setInterval(() => {
      this.reportCount =
        (this.reports.length / (this.reports.length + this.dashboards.length)) *
        100;
    }, 1500);

    this.intervalTwo = setInterval(() => {
      this.dashboardCount =
        (this.dashboards.length /
          (this.reports.length + this.dashboards.length)) *
        100;
    }, 1000);

    this.intervalThree = setInterval(() => {
      this.allAssetCount =
        (this.reports.length +
          this.dashboards.length / this.reports.length +
          this.dashboards.length) *
        100;
    }, 500);
    analytics.identify(this.$store.getters.getUserId, {
      company: this.$store.getters.getCompany,
    });
  },
};
</script>
