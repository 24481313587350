<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4" fill-height>
        <v-card class="mx-auto">
          <v-card-title>
            <v-icon color="primary" class="mr-5" size="64">
              mdi-account
            </v-icon>
            <v-row align="start">
              <div>
                <span class="display-2">{{ users.length }}</span>
                <strong class="overline"> Total Users</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>

            <v-btn
              icon
              class="align-self-start"
              size="28"
              router
              to="/Add-User"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Selected User</v-card-text
          >
          <br />
          <center>
            <v-avatar size="60" color="primary">
              <span class="white--text heading">{{
                users[selected].initials
              }}</span>
            </v-avatar>
          </center>
          <v-card-text class="headline text-center">{{
            users[selected].name
          }}</v-card-text>
          <v-card-text class="primary--text mt-n8 text-center">{{
            users[selected].email
          }}</v-card-text>
          <v-card-text class="grey--text mt-n8 text-center overline">{{
            users[selected].team
          }}</v-card-text>
          <v-card-text class="grey--text mt-n4 text-center overline"
            >Created At: {{ users[selected].createdAt }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Reports: {{ users[selected].reports }}</v-card-text
          >
          <v-card-text class="grey--text mt-n10 text-center overline"
            >Dashboards: {{ users[selected].dashboards }}</v-card-text
          >
          <v-row justify="center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  router
                  :to="
                    '/Edit-User?id=' +
                    users[selected].user_id +
                    '&firstName=' +
                    users[selected].first_name +
                    '&lastName=' +
                    users[selected].last_name +
                    '&email=' +
                    users[selected].email +
                    '&isAdmin=' +
                    users[selected].is_admin +
                    '&qna=' +
                    users[selected].qna +
                    '&canExport=' +
                    users[selected].can_export_data +
                    '&team=' +
                    users[selected].team
                  "
                  icon
                  size="50"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-btn>
              </template>
              <span>Edit User</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="error"
                  size="50"
                  @click="deleteUser"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Delete User</span>
            </v-tooltip>
          </v-row>
          <br />
          <v-divider></v-divider>
          <v-col align-content-center>
            <v-card-text class="grey--text mt-n4 text-center overline"
              >Selected User Settings</v-card-text
            >
            <v-switch
              v-model="users[selected].is_admin"
              inset
              label="Admin Access"
              @click="editAdminAccess"
            ></v-switch>
            <v-switch
              v-model="users[selected].qna"
              inset
              label="Dataset Access"
              @click="editQnaAccess"
            ></v-switch>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title"
                >User Directory</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-model="selected" color="primary">
              <v-list-item v-for="user in users" :key="user.id" two-line>
                <v-list-item-avatar color="primary">
                  <span class="white--text overline">{{ user.initials }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        router
                        :to="
                          '/Edit-User?id=' +
                          user.user_id +
                          '&firstName=' +
                          user.first_name +
                          '&lastName=' +
                          user.last_name +
                          '&email=' +
                          user.email +
                          '&isAdmin=' +
                          user.is_admin +
                          '&canExport=' +
                          user.can_export_data +
                          '&qna=' +
                          user.qna +
                          '&team=' +
                          user.team
                        "
                      >
                        <v-icon>mdi-tune</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="deleteUserList(user.user_id)"
                      >
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete User</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">Help</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <v-card-text class="heading">FAQ's</v-card-text>
          <v-expansion-panels flat>
            <v-expansion-panel v-for="faq in faqs" :key="faq.title">
              <v-expansion-panel-header>
                {{ faq.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ faq.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
          <v-card-text class="heading">Submit A Support Request</v-card-text>

          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="title"
              label="Title"
              placeholder="Enter Title Here"
              outlined
            ></v-text-field>

            <v-textarea
              v-model="message"
              outlined
              rows="4"
              name="Support"
              label="Details"
              placeholder="Enter Details Here"
            ></v-textarea>
            <v-btn rounded color="primary" @click="sendSupportRequest"
              >Submit</v-btn
            >
          </v-col>
        </v-card></v-col
      >
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
import axios from "axios";

export default {
  data: () => ({
    users: [],
    dates: [],
    selected: [0],
    faqs: [
      {
        title: "How To Share a Report/Dashboard?",
        answer:
          "On your report/dashboard list press to cog in the top left of the box, from here simply select the user/s you would like to share the report with.",
      },
      {
        title: "How To Ask A Question About Your Data?",
        answer:
          "From your dataset list press to open button or on a report press the QnA button, from here ask your question in plain text for more info there are instructions on the page.",
      },
      {
        title: "How To Add/Delete a User?",
        answer:
          "Either in your admin page, select a user from the list then press either the edit or delet button on the slected profile.",
      },
      {
        title: "How To Change Your Password?",
        answer:
          "Press the profile icon in the top left and then press edit password and fill in the details.",
      },
    ],
    hasError: false,
    errorMessage: null,
    hasSuccess: false,
    successMessage: null,
    title: null,
    message: null,
  }),

  created() {
    apiService
      .getUserCompany(this.$store.getters.getCompanyId)
      .then((response) => {
        var usercount = response.data.data.length;
        this.users = [];
        if (usercount > 0) {
          response.data.data.map((userData, index) => {
            this.users.push({
              name: userData.first_name + " " + userData.last_name,
              first_name: userData.first_name,
              last_name: userData.last_name,
              email: userData.email,
              company_id: userData.company_id,
              is_admin: userData.is_admin,
              user_id: userData.id,
              qna: userData.q_and_a,
              can_export_data: userData.can_export_data,
              createdAt:
                new Date(userData.created_at).getDate() +
                "/" +
                (new Date(userData.created_at).getMonth() + 1) +
                "/" +
                new Date(userData.created_at).getFullYear(),
              reports: userData.report,
              datasets: userData.dataset,
              dashboards: userData.dashboard,
              team: userData.team,
              initials:
                userData.first_name.substring(0, 1) +
                userData.last_name.substring(0, 1),
            });
          });
        }
        this.dates = [];
        if (usercount > 0) {
          response.data.data.map((userData, index) => {
            this.dates.push({
              createdAt: userData.createdAt,
              value: 1,
            });
          });
        }
      });

    analytics.page("Admin");
  },
  computed: {
    labels() {
      return ["Datasets", "Reports", "Dashboards"];
    },
    items() {
      return [
        this.users[this.selected].datasets.length,
        this.users[this.selected].reports.length,
        this.users[this.selected].dashboards.length,
      ];
    },
  },

  watch: {},

  methods: {
    sendSupportRequest() {
      var data = JSON.stringify({
        title: this.title,
        message: this.message,
        user: this.$store.getters.getUserId,
      });
      const headers = {
        "Content-Type": "text/plain",
      };
      axios
        .post(
          "https://hooks.zapier.com/hooks/catch/2723325/oqg2tto",
          data,
          headers
        )
        .then((response) => {
          this.successMessage = "Succesfully Sent Request";
          this.hasSuccess = true;
          this.title = null;
          this.message = null;
          analytics.track("Support Request");
        })
        .catch((error) => {
          this.errorMessage =
            "Error Sending support request please try again laters";
          this.hasError = true;
        });
    },
    deleteUser() {
      confirm("Are you sure you want to delete this user?");

      apiService
        .deleteUser(this.users[this.selected].user_id)
        .then((response) => {
          this.successMessage = "Succesfully Deleted User";
          this.hasSuccess = true;
          location.reload(true)
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    deleteUserList(user_id) {
      confirm("Are you sure you want to delete this user?");

      apiService
        .deleteUser(user_id)
        .then((response) => {
          this.successMessage = "Succesfully Deleted User";
          this.hasSuccess = true;
          location.reload(true)
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    editAdminAccess() {

      if (confirm("Are you sure you want to edit user access permissions?")) {
      apiService
        .editUser(
          this.users[this.selected].last_name,
          this.users[this.selected].first_name,
          this.users[this.selected].email,
          this.users[this.selected].user_id,
          this.users[this.selected].is_admin,
          this.users[this.selected].qna,
          this.users[this.selected].team
        )
        .then((response) => {
          this.successMessage = "Succesfully edited admin permissions for user";
          this.hasSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
      } else {
          setTimeout(this.flipAdmin, 1)
        }
    },
    editQnaAccess() {

      if (confirm("Are you sure you want to edit user Q&A permissions?")) {
        apiService
          .editUser(
            this.users[this.selected].last_name,
            this.users[this.selected].first_name,
            this.users[this.selected].email,
            this.users[this.selected].user_id,
            this.users[this.selected].is_admin,
            this.users[this.selected].qna,
            this.users[this.selected].team
          )
          .then((response) => {
            this.successMessage = "Succesfully edited Q&A permissions for user";
            this.hasSuccess = true;
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error;
            this.hasError = true;
          });
        } else {
          setTimeout(this.flipQnA, 1)
        }

    },
    flipQnA(){
      this.users[this.selected].qna = !this.users[this.selected].qna;
    },
    flipAdmin(){
      this.users[this.selected].is_admin = !this.users[this.selected].is_admin;
    },
    refreshUsers() {
      this.users = [];
      apiService
        .getUserCompany(this.$store.getters.getCompanyId)
        .then((response) => {
          var usercount = response.data.data.length;
          this.users = [];
          if (usercount > 0) {
            response.data.data.map((userData, index) => {
              this.users.push({
                name: userData.first_name + " " + userData.last_name,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                is_admin: userData.is_admin,
                user_id: userData.id,
                qna: userData.q_and_a,
                can_export_data: userData.can_export_data,
                createdAt:
                  new Date(userData.created_at).getDate() +
                  "/" +
                  (new Date(userData.created_at).getMonth() + 1) +
                  "/" +
                  new Date(userData.created_at).getFullYear(),
                reports: userData.report,
                datasets: userData.dataset,
                dashboards: userData.dashboard,
                team: userData.team,
                initials:
                  userData.first_name.substring(0, 1) +
                  userData.last_name.substring(0, 1),
              });
            });
          }
          this.dates = [];
          if (usercount > 0) {
            response.data.data.map((userData, index) => {
              this.dates.push({
                createdAt: userData.createdAt,
                value: 1,
              });
            });
          }
        });
    },
  },
};
</script>
