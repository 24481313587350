<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="12" md="4">
      <v-card class="overflow-hidden">
        <v-toolbar flat color="primary">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light ml-5">
            Reset Your Password
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form v-model="valid">
          <v-card-text>
            <v-card-text class="overline ml-n3"
              >Enter a new password</v-card-text
            >
            <v-text-field
              v-model="auth.password"
              color="primary"
              label="Password"
              type="password"
              outlined
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <v-text-field
              v-model="auth.confirmPassword"
              color="primary"
              label="Confirm Password"
              type="password"
              outlined
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined rounded @click="reset">
              Set New Password
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="hasError"
          :timeout="3000"
          bottom
          color="error"
          right
        >
          Error: {{ errorMessage }}
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      auth: {
        password: "",
        confirmPassword: "",
      },
      hasError: false,
      errorMessage: null,
    };
  },
  created() {
    analytics.page("Reset Password");
  },
  methods: {
    reset() {
      apiService
        .resetPassword(
          this.$route.query.token,
          this.auth.password,
          this.auth.confirmPassword
        )
        .then((response) => {
          analytics.track("Reset Password");
          this.$router.push({ path: "/Login" });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
  },
};
</script>
