<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" fill-height>
        <v-card elevation="10" tile>
          <v-list
            ><v-list-item>
              <v-list-item-title class="title"
                >Info - {{ reportName }}</v-list-item-title
              >
              <v-list-action>
                <v-btn icon router to="/Datasets">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-progress-circular
                :rotate="270"
                :size="100"
                :width="15"
                :value="(accessUsers.length / users.length) * 100"
                color="primary"
              >
                <span class="title"
                  >{{ accessUsers.length }}/{{ users.length }}</span
                >
              </v-progress-circular>
              <v-list-item-title class="overline pl-5"
                >Users with Access</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" fill-height>
        <v-card elevation="10" tile>
          <v-list
            ><v-list-item>
              <v-list-item-title class="title"
                >Grant Access to {{ reportName }}</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="user in users" :key="user.id" two-line>
              <v-list-item-avatar color="primary">
                <span class="white--text overline">{{ user.initials }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="shareReport(user)">
                  <v-icon color="primary">mdi-share</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" fill-height>
        <v-card elevation="10" tile>
          <v-list
            ><v-list-item>
              <v-list-item-title class="title"
                >Users With Access to {{ reportName }}</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="user in accessUsers" :key="user.id" two-line>
              <v-list-item-avatar color="primary">
                <span class="white--text overline">{{ user.initials }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="unshareReport(user)">
                  <v-icon color="primary">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";

export default {
  data() {
    return {
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      reportName: this.$route.query.report_name,
      report_id: this.$route.query.system_id,
      selected: null,
      users: [],
      accessUsers: [],
      componentKey: 0,
    };
  },
  created() {
    apiService
      .getAllUsers()
      .then((response) => {
        var usercount = response.data.data.length;
        this.users = [];
        if (usercount > 0) {
          response.data.data.map((userData, index) => {
            this.users.push({
              name: userData.first_name + " " + userData.last_name,
              user_id: userData.id,
              email: userData.email,
              initials:
                userData.first_name.substring(0, 1) +
                userData.last_name.substring(0, 1),
            });
          });
        }
      })
      .catch((error) => {});

    this.getAccessUsers();

    analytics.page("Share Dataset Master", {
      "Dataset-Name": this.$route.query.report_name,
    });
  },
  methods: {
    getAccessUsers() {
      apiService
        .getPBIDatasets()
        .then((response) => {
          var name = this.$route.query.report_name;
          var array = response.data;

          function isReport(array) {
            return array.dataset_name === name;
          }
          var currentreport = array.data.find(isReport);
          var usercount = currentreport.all_user.length;
          this.accessUsers = [];
          if (usercount > 0) {
            currentreport.all_user.map((accessUserData, index) => {
              this.accessUsers.push({
                name:
                  accessUserData.first_name + " " + accessUserData.last_name,
                user_id: accessUserData.id,
                email: accessUserData.email,
                initials:
                  accessUserData.first_name.substring(0, 1) +
                  accessUserData.last_name.substring(0, 1),
              });
            });
          }
        })
        .catch((error) => {});
    },
    shareReport(user) {
      apiService
        .assignDatasetUser(user.user_id, this.$route.query.system_id)
        .then((response) => {
          this.successMessage = response.data.message;
          this.hasSuccess = true;
          analytics.track("Share Dataset", {
            "Dataset-Name": this.$route.query.report_name,
            user: user.user_id,
          });
          this.accessUsers.push(user);
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    unshareReport(user) {
      apiService
        .unassignDatasetUser(user.user_id, this.$route.query.system_id)
        .then((response) => {
          this.successMessage = response.data.message;
          this.hasSuccess = true;
          analytics.track("Unshare Dataset", {
            "Dataset-Name": this.$route.query.report_name,
            user: user.user_id,
          });
          this.getAccessUsers();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    sharedPercentage() {},
  },
};
</script>