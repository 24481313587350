var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","fill-height":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"primary","size":"64"}},[_vm._v(" mdi-account ")]),_c('v-row',{attrs:{"align":"start"}},[_c('div',[_c('span',{staticClass:"display-2"},[_vm._v(_vm._s(_vm.users.length))]),_c('strong',{staticClass:"overline"},[_vm._v(" Total Users")])])]),_c('v-spacer'),_c('v-btn',{staticClass:"align-self-start",attrs:{"icon":"","size":"28","router":"","to":"/Add-User"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('br'),_c('v-card-text',{staticClass:"grey--text mt-n4 text-center overline"},[_vm._v("Selected User")]),_c('br'),_c('center',[_c('v-avatar',{attrs:{"size":"60","color":"primary"}},[_c('span',{staticClass:"white--text heading"},[_vm._v(_vm._s(_vm.users[_vm.selected].initials))])])],1),_c('v-card-text',{staticClass:"headline text-center"},[_vm._v(_vm._s(_vm.users[_vm.selected].name))]),_c('v-card-text',{staticClass:"primary--text mt-n8 text-center"},[_vm._v(_vm._s(_vm.users[_vm.selected].email))]),_c('v-card-text',{staticClass:"grey--text mt-n8 text-center overline"},[_vm._v(_vm._s(_vm.users[_vm.selected].team))]),_c('v-card-text',{staticClass:"grey--text mt-n4 text-center overline"},[_vm._v("Created At: "+_vm._s(_vm.users[_vm.selected].createdAt))]),_c('v-card-text',{staticClass:"grey--text mt-n10 text-center overline"},[_vm._v("Reports: "+_vm._s(_vm.users[_vm.selected].reports))]),_c('v-card-text',{staticClass:"grey--text mt-n10 text-center overline"},[_vm._v("Dashboards: "+_vm._s(_vm.users[_vm.selected].dashboards))]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"router":"","to":'/Edit-User?id=' +
                  _vm.users[_vm.selected].user_id +
                  '&firstName=' +
                  _vm.users[_vm.selected].first_name +
                  '&lastName=' +
                  _vm.users[_vm.selected].last_name +
                  '&email=' +
                  _vm.users[_vm.selected].email +
                  '&isAdmin=' +
                  _vm.users[_vm.selected].is_admin +
                  '&qna=' +
                  _vm.users[_vm.selected].qna +
                  '&canExport=' +
                  _vm.users[_vm.selected].can_export_data +
                  '&team=' +
                  _vm.users[_vm.selected].team,"icon":"","size":"50"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('span',[_vm._v("Edit User")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","size":"50"},on:{"click":_vm.deleteUser}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Delete User")])])],1),_c('br'),_c('v-divider'),_c('v-col',{attrs:{"align-content-center":""}},[_c('v-card-text',{staticClass:"grey--text mt-n4 text-center overline"},[_vm._v("Selected User Settings")]),_c('v-switch',{attrs:{"inset":"","label":"Admin Access"},on:{"click":_vm.editAdminAccess},model:{value:(_vm.users[_vm.selected].is_admin),callback:function ($$v) {_vm.$set(_vm.users[_vm.selected], "is_admin", $$v)},expression:"users[selected].is_admin"}}),_c('v-switch',{attrs:{"inset":"","label":"Dataset Access"},on:{"click":_vm.editQnaAccess},model:{value:(_vm.users[_vm.selected].qna),callback:function ($$v) {_vm.$set(_vm.users[_vm.selected], "qna", $$v)},expression:"users[selected].qna"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","fill-height":""}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("User Directory")])],1),_c('v-divider'),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.users),function(user){return _c('v-list-item',{key:user.id,attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text overline"},[_vm._v(_vm._s(user.initials))])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(user.email))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","router":"","to":'/Edit-User?id=' +
                        user.user_id +
                        '&firstName=' +
                        user.first_name +
                        '&lastName=' +
                        user.last_name +
                        '&email=' +
                        user.email +
                        '&isAdmin=' +
                        user.is_admin +
                        '&canExport=' +
                        user.can_export_data +
                        '&qna=' +
                        user.qna +
                        '&team=' +
                        user.team}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit User")])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteUserList(user.user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete User")])])],1)],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","fill-height":""}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("Help")])],1),_c('v-divider')],1),_c('v-card-text',{staticClass:"heading"},[_vm._v("FAQ's")]),_c('v-expansion-panels',{attrs:{"flat":""}},_vm._l((_vm.faqs),function(faq){return _c('v-expansion-panel',{key:faq.title},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(faq.title)+" ")]),_c('v-expansion-panel-content',[_vm._v(" "+_vm._s(faq.answer)+" ")])],1)}),1),_c('v-divider'),_c('v-card-text',{staticClass:"heading"},[_vm._v("Submit A Support Request")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Title","placeholder":"Enter Title Here","outlined":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-textarea',{attrs:{"outlined":"","rows":"4","name":"Support","label":"Details","placeholder":"Enter Details Here"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.sendSupportRequest}},[_vm._v("Submit")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"bottom":"","color":"error","right":""},model:{value:(_vm.hasError),callback:function ($$v) {_vm.hasError=$$v},expression:"hasError"}},[_vm._v(" Error: "+_vm._s(_vm.errorMessage)+" ")]),_c('v-snackbar',{attrs:{"timeout":3000,"bottom":"","color":"success","right":""},model:{value:(_vm.hasSuccess),callback:function ($$v) {_vm.hasSuccess=$$v},expression:"hasSuccess"}},[_vm._v(" Success: "+_vm._s(_vm.successMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }