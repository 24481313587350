import axios from 'axios'
import store from '../store/store'


const apiClient = axios.create({
  baseURL: 'https://discovr-backend-sqonh.ondigitalocean.app',
  withCredential: true,
  headers: {
    'Authorization': store.getters.getToken
  }
})

export default {

  //User & Company Functions

  getUser(id) {
    return apiClient.get('/user/own/get/' + id, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getCompany(id) {
    return apiClient.get('/companies/get/' + id, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getAllCompany() {
    return apiClient.get('/companies/all', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getAllUsers() {
    return apiClient.get('/user/superadmin/get', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getUserCompany(id) {
    return apiClient.get('/user/admin/get/' + id, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getMasterUsers() {
    return apiClient.get('/user/master/', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getAdminUsers() {
    return apiClient.get('/user/admin/', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  addUser(first_name, last_name, email, company_id, is_admin, is_qna, team, canExport) {
    return apiClient.post('/user/admin/add', {
      "firstName": first_name,
      "lastName": last_name,
      "email": email,
      "companyId": company_id,
      "isAdmin": is_admin,
      "isQAndA": is_qna,
      "team": team,
      "canExport": canExport
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  addCompany(company_name, company_description, company_logo, primiary_color, subdomain_url) {
    return apiClient.post('/companies/add', {
      "company_name": company_name,
      "company_description": company_description,
      "company_logo": company_logo,
      "primiary_color": primiary_color,
      "subdomain_url": subdomain_url

    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  deleteUser(user_id) {
    return apiClient.delete('/user/delete/' + user_id, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getTeams(companyId) {
    return apiClient.get('/teams/get/' + companyId, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  editUser(first_name, last_name, email, user_id, is_admin, qna, team, canExport) {
    return apiClient.put('/user/edit/', {
      "first_name": first_name,
      "last_name": last_name,
      "email": email,
      "user_id": user_id,
      "is_admin": is_admin,
      "q_and_a": qna,
      "team": team,
      "canExport": canExport
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  editOwnEmail(email, userId) {
    return apiClient.put('/user/email/edit/' + userId, {
      "email": email,
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  editOwnPassword(oldPassword, newPassword, confirmPassword, userId) {
    return apiClient.put('/user/password/edit/' + userId, {
      "plainPassword": newPassword,
      "oldPassword": oldPassword,
      "confirmPassword": confirmPassword
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  deleteCompany(company_id) {
    return apiClient.delete('/company/' + company_id, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  editCompany(company_id, subdomain_url, company_name, company_description, company_logo, primiary_color) {
    return apiClient.put('/company/', {
      "id": company_id,
      "company_name": company_name,
      "subdomain_url": subdomain_url,
      "company_description": company_description,
      "company_logo": company_logo,
      "primiary_color": primiary_color
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  suspendCompany(company_id) {
    return apiClient.post('/company/suspend/', {
      "id": company_id,
      "Suspend": true
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },

  getRecent(userId) {
    return apiClient.get('/user/recent/' + userId, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },

  //Data Sharing Functions

  assignReportUser(user_id, report_id) {
    return apiClient.post('/reports/share/', {
      "reportId": report_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unassignReportUser(user_id, report_id) {
    return apiClient.post('/reports/unshare/', {
      "reportId": report_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  assignReportCompany(company_id, report_id) {
    return apiClient.post('/report/share/company/', {
      "company_id": company_id,
      "report_id": report_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unassignReportCompany(company_id, report_id) {
    return apiClient.post('/report/unshare/company/', {
      "company_id": company_id,
      "report_id": report_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  assignDashboardUser(user_id, report_id) {
    return apiClient.post('/dashboards/share/', {
      "dashboardId": report_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unassignDashboardUser(user_id, report_id) {
    return apiClient.post('/dashboards/unshare/', {
      "dashboardId": report_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  assignDatasetUser(user_id, dataset_id) {
    return apiClient.post('/datasets/share', {
      "datasetId": dataset_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unassignDatasetUser(user_id, dataset_id) {
    return apiClient.post('/datasets/unshare', {
      "datasetId": dataset_id,
      "userId": user_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  assignDashboardCompany(company_id, report_id) {
    return apiClient.post('/dashboard/share/company/', {
      "company_id": company_id,
      "dashboard_id": report_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unassignDashboardCompany(company_id, report_id) {
    return apiClient.post('/dashboard/unshare/company/', {
      "company_id": company_id,
      "dashboard_id": report_id
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  // Group Sharing Functions
  shareReportGroup(reportId, team, companyId) {
    return apiClient.post('/reports/team/share', {
      "team": team,
      "reportId": reportId,
      "companyId": companyId
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unshareReportGroup(reportId, team, companyId) {
    return apiClient.post('/reports/team/unshare', {
      "team": team,
      "reportId": reportId,
      "companyId": companyId
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  shareDashboardGroup(dashboardId, team, companyId) {
    return apiClient.post('/dashboards/team/share', {
      "team": team,
      "dashboardId": dashboardId,
      "companyId": companyId
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  unshareDashboardGroup(dashboardId, team, companyId) {
    return apiClient.post('/dashboards/team/unshare', {
      "team": team,
      "dashboardId": dashboardId,
      "companyId": companyId
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },

  //Password Functions

  login(email, password) {
    return apiClient.post('/auth/login', {
      "email": email,
      "password": password

    })
  },
  logout() {
    return apiClient.delete('/auth/logout', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  forgotPassword(email) {
    return apiClient.post('/auth/forgot', {
      "email": email
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  resetPassword(token, password, confirm_password) {
    return apiClient.post('/auth/reset', {
      "token": token,
      "password": password,
      "confirm_password": confirm_password
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  changePassword(old_password, password, confirm_password) {
    return apiClient.put('/user/change/password', {
        "old_password": old_password,
        "password": password,
        "confirm_password": confirm_password
      }, {
        headers: {
          'Authorization': store.getters.getToken
        }
      }

    )
  },

  //Power Bi Embeded Functions

  getPBIReports() {
    return apiClient.get('/reports/all', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getPBIDashboards() {
    return apiClient.get('/dashboards/all', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getPBIDatasets() {
    return apiClient.get('/datasets/all', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getReportRefreshHistory(datasetId) {
    return apiClient.post('/powerbi/refreshes', {
      "id": datasetId
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  refreshReports() {
    return apiClient.get('/powerbi/reports/', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  refreshDashboards() {
    return apiClient.get('/powerbi/dashboards/', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  refreshDatasets() {
    return apiClient.get('/powerbi/datasets', {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getPBITokenDashboard(typeid) {
    return apiClient.post('/powerbi/embed/token', {
      type: "dashboards",
      type_id: typeid
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getPBITokenReport(typeid) {
    return apiClient.post('/powerbi/embed/token', {
      type: "reports",
      type_id: typeid
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },
  getPBITokenQna(typeid) {
    return apiClient.post('/powerbi/embed/token', {
      type: "datasets",
      type_id: typeid
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },



  // EXPORTS

  getExports(userId) {
    return apiClient.get('/exports/get/' + userId, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },

  startExport(userId, exportName, state, reportId) {
    return apiClient.post('/exports/start', {
      userId: userId,
      reportId: reportId,
      state: state,
      exportName: exportName
    }, {
      headers: {
        'Authorization': store.getters.getToken
      }
    })
  },

  downloadExport(url) {
    return apiClient.post('/exports/download', {
      url: url
    }, {
      headers: {
        'Authorization': store.getters.getToken
      },
      responseType: 'arraybuffer'
    })
  }


}