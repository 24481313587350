<template>

  <v-app id="inspire">

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
      v-show="this.$store.state.isAuth"
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in nav"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
      v-show="this.$store.state.isAuth"
    >
    
      <v-btn icon @click.stop="miniVariant = !miniVariant">
        <v-icon>mdi-{{ `chevron-${miniVariant ? "right" : "left"}` }}</v-icon>
      </v-btn>
      <v-toolbar-title
        ><strong>{{ company }}</strong> | {{ companyDesc }}</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon @click.stop="rightDrawer = !rightDrawer">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>


    <v-navigation-drawer
      v-model="rightDrawer"
      :right="right"
      temporary
      fixed
      width="40%"
    >
      <v-list>
        <v-list-item>
          <v-list-item-title class="headline">Profile</v-list-item-title>
          <v-list-action>
            <v-btn icon @click="rightDrawer = !rightDrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-action>
        </v-list-item>
      </v-list>
      <br />
      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" md="10">
          <v-row justify="center" align="center">
            <v-img :src="companylogo" max-height="25%" max-width="50%"></v-img>
          </v-row>
          <br />
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col class="mb-n10" cols="12" md="12">
                    <v-text-field
                      v-model="firstName"
                      :value="firstName"
                      :rules="nameRules"
                      label="First name"
                      required
                      :placeholder="firstName"
                      outlined
                      prepend-inner-icon="mdi-account"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-n10" cols="12" md="12">
                    <v-text-field
                      v-model="lastName"
                      :value="lastName"
                      :rules="nameRules"
                      label="Last name"
                      required
                      :placeholder="lastName"
                      outlined
                      prepend-inner-icon="mdi-account"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-n10" cols="12" md="12">
                    <v-text-field
                      v-show="editingEmail"
                      v-model="email"
                      :value="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      :placeholder="email"
                      outlined
                      clearable
                      prepend-inner-icon="mdi-email"
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-n10" cols="12" md="12">
                    <v-text-field
                      v-if="editingPassword"
                      v-model="oldPassword"
                      :rules="passwordRules"
                      label="Current Password"
                      required
                      type="password"
                      outlined
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-n10" cols="12" md="12">
                    <v-text-field
                      v-if="editingPassword"
                      v-model="newPassword"
                      :rules="passwordRules"
                      label="Password"
                      required
                      type="password"
                      outlined
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-if="editingPassword"
                      v-model="confirmPassword"
                      :rules="comfirmPasswordRules"
                      label="Confirm Password"
                      required
                      type="password"
                      outlined
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-row>
          <v-row justify="space-between">
            <v-btn
              rounded
              v-show="!editingEmail && !editingPassword"
              color="primary"
              @click="editEmail"
              >Edit Email</v-btn
            >
            <v-btn
              rounded
              v-show="editingEmail"
              color="primary"
              @click="sendEditEmail"
              >Update Email</v-btn
            >
            <v-btn
              rounded
              v-show="!editingEmail && !editingPassword"
              color="primary"
              @click="editPassword"
              >Edit Password</v-btn
            >
            <v-btn
              rounded
              v-show="editingPassword"
              color="primary"
              @click="sendEditPassword"
              >Update Password</v-btn
            >
            <v-btn
              rounded
              v-show="editingEmail || editingPassword"
              color="error"
              @click="cancelEdit"
              >Cancel</v-btn
            >
            <v-btn rounded color="error" @click="logout"> Logout </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-main>

      <router-view></router-view>

    </v-main>

    <!-- <v-footer :absolute="!fixed" app>
      <span
        >Built by
        <a href="https://discovranalytics.co" target="_blank"
          >Discovr Analytics Limited &copy; {{ new Date().getFullYear() }}</a
        ></span
      >
    </v-footer> -->
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" left>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      left
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import apiService from "./services/services.js";
export default {
  data() {
    return {
      isAuth: this.$store.getters.isAuth,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      editingEmail: false,
      editingPassword: false,
      email: this.$store.getters.getEmail,
      valid: false,
      clipped: true,
      drawer: true,
      fixed: false,
      companylogo: this.$store.getters.getLogo,
      miniVariant: true,
      right: true,
      rightDrawer: false,
      company: null,
      companyDesc: null,
      dashboardCount: null,
    };
  },
  created() {
    if(this.$store.getters.isAuth){
    apiService
      .getPBIDashboards()
      .then((response) => {
        this.dashboardCount = response.data.data.length;
      })
    }
  },
  computed: {
    items() {
      if (
        !this.$store.getters.isMaster &&
        this.$store.getters.isAdmin &&
        !this.$store.getters.isQna
      ) {
        var adminNav = [
          {
            icon: "mdi-apps",
            title: "Home",
            to: "/",
          },
          {
            icon: "mdi-television-guide",
            title: "Dashboards",
            to: "/Dashboards",
          },
          {
            icon: "mdi-chart-line",
            title: "Reports",
            to: "/Reports",
          },
          {
            icon: "mdi-cloud-download",
            title: "Exports",
            to: "/Exports",
          },
          {
            icon: "mdi-cog",
            title: "Admin",
            to: "/Admin",
          },
        ];
        return adminNav;
      } else if (
        !this.$store.getters.isMaster &&
        this.$store.getters.isAdmin &&
        this.$store.getters.isQna
      ) {
        var adminNavQna = [
          {
            icon: "mdi-apps",
            title: "Home",
            to: "/",
          },
          {
            icon: "mdi-television-guide",
            title: "Dashboards",
            to: "/Dashboards",
          },
          {
            icon: "mdi-chart-line",
            title: "Reports",
            to: "/Reports",
          },
          {
            icon: "mdi-cloud-download",
            title: "Exports",
            to: "/Exports",
          },
          {
            icon: "mdi-database",
            title: "Datasets",
            to: "/Datasets",
          },
          {
            icon: "mdi-cog",
            title: "Admin",
            to: "/Admin",
          },
        ];
        return adminNavQna;
      } else if (this.$store.getters.isMaster && this.$store.getters.isAdmin) {
        var masterNav = [
          {
            icon: "mdi-apps",
            title: "Home",
            to: "/",
          },
          {
            icon: "mdi-television-guide",
            title: "Dashboards",
            to: "/Dashboards",
          },
          {
            icon: "mdi-chart-line",
            title: "Reports",
            to: "/Reports",
          },
          {
            icon: "mdi-cloud-download",
            title: "Exports",
            to: "/Exports",
          },
          {
            icon: "mdi-database",
            title: "Datasets",
            to: "/Datasets",
          },
          {
            icon: "mdi-lock",
            title: "Master Admin",
            to: "/Master-Admin",
          },
        ];
        return masterNav;
      } else if (
        !this.$store.getters.isMaster &&
        !this.$store.getters.isAdmin &&
        this.$store.getters.isQna
      ) {
        var standardNavQna = [
          {
            icon: "mdi-apps",
            title: "Home",
            to: "/",
          },
          {
            icon: "mdi-television-guide",
            title: "Dashboards",
            to: "/Dashboards",
          },
          {
            icon: "mdi-chart-line",
            title: "Reports",
            to: "/Reports",
          },
          {
            icon: "mdi-cloud-download",
            title: "Exports",
            to: "/Exports",
          },
          {
            icon: "mdi-database",
            title: "Datasets",
            to: "/Datasets",
          },
        ];
        return standardNavQna;
      } else {
        var standardNav = [
          {
            icon: "mdi-apps",
            title: "Home",
            to: "/",
          },
          {
            icon: "mdi-television-guide",
            title: "Dashboards",
            to: "/Dashboards",
          },
          {
            icon: "mdi-chart-line",
            title: "Reports",
            to: "/Reports",
          },
          {
            icon: "mdi-cloud-download",
            title: "Exports",
            to: "/Exports",
          },
        ];
        return standardNav;
      }
    },
    nav() {

      function checkDash(item) {
        return item.title != 'Dashboards'
      }

      if(this.dashboardCount == 0){
        return this.items.filter(checkDash)
      } else {
        return this.items
      }
    },
    firstName() {
      var firstName = this.$store.getters.getFirstName;
      return firstName;
    },
    lastName() {
      var lastName = this.$store.getters.getLastName;
      return lastName;
    },

    initials() {
      if (this.firstName != undefined) {
        var data =
          this.firstName.substring(0, 1) + this.lastName.substring(0, 1);
        return data;
      } else {
        return null;
      }
    },
  },
  updated() {
    this.company = this.$store.getters.getCompany;
    this.companyDesc = this.$store.getters.getCompanyDesc;
    if (this.$store.getters.getColor != null) {
      this.$vuetify.theme.themes.light.primary = this.$store.getters.getColor;
    }
  },
  methods: {
    editEmail() {
      this.editingEmail = true;
    },
    editPassword() {
      this.editingPassword = true;
    },
    cancelEdit() {
      this.editingEmail = false;
      this.editingPassword = false;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
    },
    sendEditEmail() {
      apiService
        .editOwnEmail(this.email, this.$store.getters.getUserId )
        .then((response) => {
          this.successMessage = "Succesfully Edited Email";
          this.hasSuccess = true;
          this.$store.commit("editEmail", this.email)

        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
    sendEditPassword() {
      console.log('called', apiService.editOwnPassword)
      apiService
        .editOwnPassword(
          this.oldPassword,
          this.newPassword,
          this.confirmPassword,
          this.$store.getters.getUserId
        )
        .then((response) => {

          this.successMessage = "Succesfully Edited Password";
          this.hasSuccess = true;
          this.oldPassword = null;
          this.newPassword = null;
          this.confirmPassword = null;
        })
        .catch((error) => {
          console.log(error)
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });

      console.log('fin')
    },
    logout() {
      apiService.logout().then((response) => {
        if (response.status === 200) {
          this.oldPassword = null;
          this.newPassword = null;
          this.confirmPassword = null;
          this.$store.commit("logOut");
          this.$router.push({ path: "/login" });
        }
      });
    },
  },
};
</script>
