<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="12" md="4">
      <v-card class="overflow-hidden">
        <v-toolbar flat color="primary">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light ml-5">
            Request A New Password
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form v-model="valid">
          <v-card-text>
            <v-card-text class="overline ml-n3"
              >Enter your email address, you will be sent a code to use to reset
              your password.</v-card-text
            >
            <v-text-field
              v-model="auth.email"
              color="primary"
              label="Email"
              type="email"
              :rules="emailRules"
              outlined
              prepend-inner-icon="mdi-email"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined rounded @click="forgotPassword">
              Reset Password
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="hasError"
          :timeout="3000"
          bottom
          color="error"
          right
        >
          Error: {{ errorMessage }}
        </v-snackbar>
        <v-snackbar
          v-model="hasSuccess"
          :timeout="3000"
          bottom
          color="success"
          right
        >
          {{ successMessage }}
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      auth: {
        email: "",
      },
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      emailRules: [(v) => /.+@.+/.test(v) || "Please use valid E-mail format"],
    };
  },
  created() {
    analytics.page("Forgot Password");
  },
  methods: {
    forgotPassword() {
      apiService
        .forgotPassword(this.auth.email.toLowerCase())
        .then((response) => {
          this.successMessage =
            "Request Sent Successfully, Check your email for the reset code";
          this.hasSuccess = true;
          analytics.track("Request Reset Password", {
            "user email": this.auth.email.toLowerCase(),
          });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
  },
};
</script>
