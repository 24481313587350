import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Reports from '../views/Reports.vue'
import Report from '../views/Report.vue'
import Dashboards from '../views/Dashboards.vue'
import Dashboard from '../views/Dashboard.vue'
import Datasets from '../views/Datasets.vue'
import Question from '../views/Question.vue'
import ShareReport from '../views/ShareReport.vue'
import ShareDashboard from '../views/ShareDashboard.vue'
import ShareDataset from '../views/ShareDataset.vue'
import ShareReportMaster from '../views/ShareReportMaster.vue'
import ShareDatasetMaster from '../views/ShareDatasetMaster.vue'
import ShareDashboardMaster from '../views/ShareDashboardMaster.vue'
import Admin from '../views/Admin.vue'
import AddUser from '../views/AddUser.vue'
import EditUser from '../views/EditUser.vue'
import AddUserMaster from '../views/AddUserMaster.vue'
import AdminMaster from '../views/AdminMaster.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import AddCompanyMaster from '../views/AddCompany.vue'
import Exports from '../views/Exports.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Forgot-Password',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/Reset',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/Reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Report',
    name: 'Report',
    component: Report,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Share-Report',
    name: 'Share Report',
    component: ShareReport,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Share-Report-Master',
    name: 'Share Report Master',
    component: ShareReportMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
  {
    path: '/Dashboards',
    name: 'Dashboards',
    component: Dashboards,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Share-Dashboard',
    name: 'Share Dashboard',
    component: ShareDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Share-Dashboard-Master',
    name: 'Share Dashboard Master',
    component: ShareDashboardMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
  {
    path: '/Datasets',
    name: 'Datasets',
    component: Datasets,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Share-Dataset',
    name: 'Share Dataset',
    component: ShareDataset,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Share-Dataset-Master',
    name: 'Share Dataset Master',
    component: ShareDatasetMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
  {
    path: '/Question',
    name: 'Question',
    component: Question,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Exports',
    name: 'Exports',
    component: Exports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Master-Admin',
    name: 'Master Admin',
    component: AdminMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
  {
    path: '/Add-User',
    name: 'Add User',
    component: AddUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Edit-User',
    name: 'Edit User',
    component: EditUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/Add-User-Master',
    name: 'Add User Master',
    component: AddUserMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
  {
    path: '/Add-Company-Master',
    name: 'Add Company Master',
    component: AddCompanyMaster,
    meta: {
      requiresAuth: true,
      requiresMaster: true,
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      next()
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin && store.getters.isAuth) {
      next()
      return
    }
    next('/')
  } else if (to.matched.some(record => record.meta.requiresMaster)) {
    if (store.getters.isMaster && store.getters.isAuth) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router