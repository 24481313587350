<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">What is Q&A?</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <v-card-text>
            Sometimes the fastest way to get an answer from your data is to ask
            a question using natural language.
            <br /><br />
            For example, "what were total sales last year." Use Q&A to explore
            your data using intuitive, natural language capabilities and receive
            answers in the form of charts and graphs. <br />
            <br />
            Q&A is different from a search engine -- Q&A only provides results
            about the data in the Dataset.
          </v-card-text>
          <v-card-text>
            <strong>Which visualization does Q&A use?</strong><br /><br />
            Q&A picks the best visualization based on the data being displayed.
            Sometimes data in the underlying dataset is defined as a certain
            type or category and this helps Q&A know how to display it. For
            example, if data is defined as a date type, it is more likely to be
            displayed as a line chart.
            <br /><br />You can also tell Q&A which visual to use by adding it
            to your question. Q&A will prompt you with a list of workable visual
            types.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" fill-height>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="title"
                >Ask A Question About Your Data</v-list-item-title
              >
              <v-list-action>
                <v-btn icon router to="/Datasets">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-action>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <div id="qnaContainer" style="width: 99%; height: 625px"></div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      pbitoken: null,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
    };
  },
  created: function () {
    this.reportname = this.$route.query.report_name;
    apiService
      .getCompany(1)
      .then((response) => {
        this.company = response.data.data.company_name;
      })
      .catch((error) => {})
      .then((response) => (this.company = response.data.data.company_name));

    apiService
      .getPBITokenReport(this.$route.query.report_id)
      .then((response) => {
        var models = window["powerbi-client"].models;
        var embedConfiguration = {
          type: "qna",
          datasetIds: [this.$route.query.dataset_id],
          embedUrl: "https://app.powerbi.com/qnaEmbed",
          tokenType: models.TokenType.Embed,
          accessToken: response.data.token,
          viewMode: models.QnaMode.Interactive,
        };

        var $qnaContainer = $("#qnaContainer");
        var qna = powerbi.embed($qnaContainer.get(0), embedConfiguration);
      })
      .catch((error) => {
        this.errorMessage = error.response.data.error;
        this.hasError = true;
      });

    analytics.page("QnA", {
      "Question-Name": this.$route.query.report_name,
    });
  },
  mounted() {},
  methods: {
    fullScreen() {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report.fullscreen();
      analytics.track("FullScreen", {
        "Report-Name": this.$route.query.report_name,
      });
    },
    printReport() {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report.print();
      analytics.track("Print", {
        "Report-Name": this.$route.query.report_name,
      });
    },
    close() {
      analytics.track("Close Report", {
        "Report-Name": this.$route.query.report_name,
      });
      this.$router.push({ path: "/reports" });
    },
  },
};
</script>

<style>
#reportContainer,
.reportContainer {
  background-color: transparent;
}
.desktop-view iframe,
.mobile-view iframe,
iframe {
  border: none;
  background-color: transparent;
}
</style>