<template>
  <v-container fluid>
    <v-data-iterator
      :items="reports"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar flat color="transparent" class="mb-1">
          <v-autocomplete
            v-model="search"
            :items="reportNames"
            clearable
            flat
            outlined
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-autocomplete>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="primary" :value="false">
                <v-icon color="white">mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="primary" :value="true">
                <v-icon color="white">mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row class="pa-4">
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-title class="subheading font-weight-bold">{{
                    item.name
                  }}</v-list-item-title>
                  <v-list-action v-show="$store.getters.isMaster">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          router
                          :to="
                            '/share-report-master?system_id=' +
                            item.system_id +
                            '&report_name=' +
                            item.name
                          "
                        >
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>Master Setting</span>
                    </v-tooltip>
                  </v-list-action>
                  <v-list-action v-show="$store.getters.isAdmin">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          router
                          :to="
                            '/share-report?system_id=' +
                            item.system_id +
                            '&report_name=' +
                            item.name
                          "
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="rightDrawer = !rightDrawer"
                        >
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>Setting</span>
                    </v-tooltip>
                  </v-list-action>
                  <v-list-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          router
                          :to="
                            '/report?report_id=' +
                            item.id +
                            '&dataset_id=' +
                            item.dataset_id +
                            '&report_name=' +
                            item.name +
                            '&created=' +
                            item.created
                          "
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="rightDrawer = !rightDrawer"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Open {{ item.name }}</span>
                    </v-tooltip>
                  </v-list-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>

              <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="align-end overline"
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="pa-5" align="center" justify="center">
          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn fab dark color="primary" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="primary" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  props: [],
  data() {
    return {
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: "name",
      keys: ["Name", "Created"],
      reports: [],
      datasets: [],
      merged: [],
      value: null,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.reports.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    reportNames() {
      let allReports = this.reports;
      var names = [];
      let length = allReports.length;
      if (length > 0) {
        allReports.map((reportData, index) => {
          names.push(reportData.name);
        });
      }
      return names;
    },
  },
  created() {
    apiService
      .getPBIReports()
      .then((response) => {
        var reportcount = response.data.data.length;
        this.reports = [];
        if (reportcount > 0) {
          response.data.data.map((reportData, index) => {
            this.reports.push({
              name: reportData.report_name,
              id: reportData.report_power_bi_id,
              dataset_id: reportData.dataset_id,
              system_id: reportData.id,
              created:
                new Date(reportData.created_at).getDate() +
                "/" +
                (new Date(reportData.created_at).getMonth() + 1) +
                "/" +
                new Date(reportData.created_at).getFullYear(),
            });
            this.successMessage = "Succesfully Found Reports";
            this.hasSuccess = true;
            return index;
          });
        }
      })
      .catch((error) => {
        this.errorMessage = error.response.data.error;
        this.hasError = true;
      });

    analytics.page("Report List");
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
      analytics.track("Reports Next Page");
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
      analytics.track("Reports Previous Page");
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      analytics.track("Reports Update Items Per Page");
    },
    async getReports() {},
  },
};
</script>
