<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" fill-height>
        <v-card class="mx-auto">
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">Add A Company</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-stepper v-model="e6" vertical flat>
            <v-stepper-step editable :complete="e6 > 1" step="1">
              Company Details
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="name"
                  label="Name"
                  placeholder="Add Name Here"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="description"
                  label="Description"
                  placeholder="Add Description Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 2"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 2" step="2">
              Branding
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="logo"
                  label="Logo"
                  placeholder="Add Logo URL Here"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="color"
                  label="Color"
                  placeholder="Add Hex Code Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 3"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 3" step="3">
              Access
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="subdomain"
                  label="Subdomain"
                  placeholder="Add Company Subdomain Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 4"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable step="4"> Review & Submit </v-stepper-step>
            <v-stepper-content step="4">
              <v-card-text class="overline pb-1">Name: {{ name }}</v-card-text>
              <v-card-text class="overline pb-1"
                >Description: {{ description }}</v-card-text
              >
              <v-card-text class="overline pb-1">Logo: {{ logo }}</v-card-text>
              <v-card-text class="overline pb-1"
                >Color: {{ color }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Subdomain: {{ subdomain }}</v-card-text
              >
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="addUser"> Submit </v-btn>
              </v-col>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      e6: 1,
      name: null,
      description: null,
      logo: null,
      color: null,
      subdomain: null,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
    };
  },
  created() {
    analytics.page("Add Company");
  },
  methods: {
    addUser() {
      apiService
        .addCompany(
          this.name,
          this.description,
          this.logo,
          this.color,
          this.subdomain
        )
        .then((response) => {
          analytics.track("Add Company", {
            company: this.name,
          });
          this.$router.push({ path: "/Master-Admin" });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
  },
};
</script>
