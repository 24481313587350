var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-iterator',{attrs:{"items":_vm.reports,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"sort-by":_vm.sortBy.toLowerCase(),"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":"","color":"transparent"}},[_c('v-autocomplete',{attrs:{"items":_vm.reportNames,"clearable":"","flat":"","outlined":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","value":false}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","value":true}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-down")])],1)],1)]:_vm._e()],2)]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',{staticClass:"pa-4"},_vm._l((props.items),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"subheading font-weight-bold"},[_vm._v(_vm._s(item.name))]),_c('v-list-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.isMaster),expression:"$store.getters.isMaster"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","router":"","to":'/share-report-master?system_id=' +
                          item.system_id +
                          '&report_name=' +
                          item.name}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Master Setting")])])],1),_c('v-list-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.isAdmin),expression:"$store.getters.isAdmin"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"router":"","to":'/share-report?system_id=' +
                          item.system_id +
                          '&report_name=' +
                          item.name,"icon":""},on:{"click":function($event){_vm.rightDrawer = !_vm.rightDrawer}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Setting")])])],1),_c('v-list-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"router":"","to":'/report?report_id=' +
                          item.id +
                          '&dataset_id=' +
                          item.dataset_id +
                          '&report_name=' +
                          item.name +
                          '&created=' +
                          item.created,"icon":""},on:{"click":function($event){_vm.rightDrawer = !_vm.rightDrawer}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Open "+_vm._s(item.name))])])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filteredKeys),function(key,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{class:{ 'blue--text': _vm.sortBy === key }},[_vm._v(" "+_vm._s(key)+": ")]),_c('v-list-item-content',{staticClass:"align-end overline",class:{ 'blue--text': _vm.sortBy === key }},[_vm._v(" "+_vm._s(item[key.toLowerCase()])+" ")])],1)}),1)],1)],1)}),1)]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"pa-5",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"grey--text"},[_vm._v("Items per page")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),_c('span',{staticClass:"mr-4 grey--text"},[_vm._v(" Page "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}])}),_c('v-snackbar',{attrs:{"timeout":3000,"bottom":"","color":"error","right":""},model:{value:(_vm.hasError),callback:function ($$v) {_vm.hasError=$$v},expression:"hasError"}},[_vm._v(" Error: "+_vm._s(_vm.errorMessage)+" ")]),_c('v-snackbar',{attrs:{"timeout":3000,"bottom":"","color":"success","right":""},model:{value:(_vm.hasSuccess),callback:function ($$v) {_vm.hasSuccess=$$v},expression:"hasSuccess"}},[_vm._v(" Success: "+_vm._s(_vm.successMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }