<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-row>

        <v-col cols="12" sm="12" md="10" lg="10" fill-height>
          <div id="reportContainer" style="width: 100%; height: 82vh"></div>
          <template>
            <v-overlay
              :value="isLoading"
              opacity="100%"
              color="white"
            >
              <v-progress-circular
                :size="100"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
          </template>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="2" fill-height>
          <v-card elevation="10" tile>
            <v-list
              ><v-list-item>
                <v-list-item-title class="headline">Actions</v-list-item-title>
                <v-list-action>
                  <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group v-model="item" color="primary">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="overline">{{
                      reportname
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      currentPageName
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="created != null" two-line>
                  <v-list-item-content>
                    <v-list-item-title class="overline">{{
                      created
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Created</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider></v-divider>
              <v-list-group v-show="reportPages.length > 1" :value="false">
                <template v-slot:activator>
                  <v-list-item-title class="overline">Pages</v-list-item-title>
                </template>

                <v-list-item
                  v-for="reportPage in reportPages"
                  :key="reportPage.name"
                >
                  <v-list-item-title
                    @click="goToPage(reportPage.name)"
                    class="caption"
                    >{{ reportPage.displayName }}
                  </v-list-item-title>
                  <v-list-action>
                    <v-btn icon @click="goToPage(reportPage.name)">
                      <v-icon small>mdi-open-in-app</v-icon>
                    </v-btn>
                  </v-list-action>
                </v-list-item>
              </v-list-group>

              <v-divider></v-divider>
              <v-list-item v-show="reportPages.length > 1">
                <v-list-item-title class="overline"
                  >Page {{ pageNumber + 1 }}/{{ reportPages.length }}
                </v-list-item-title>
                <v-list-action>
                  <v-btn
                    :disabled="pageNumber === 0"
                    icon
                    @click="previousPage"
                  >
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                  </v-btn>
                </v-list-action>
                <v-list-action>
                  <v-btn
                    :disabled="pageNumber === reportPages.length - 1"
                    icon
                    @click="nextPage"
                  >
                    <v-icon color="primary">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>

              <v-list-item v-show="$store.getters.isQna">
                <v-list-item-title class="overline"
                  >Ask a Question
                </v-list-item-title>
                <v-list-action>
                  <v-btn
                    icon
                    router
                    :to="
                      '/question?report_id=' +
                      $route.query.report_id +
                      '&dataset_id=' +
                      $route.query.dataset_id +
                      '&report_name=' +
                      $route.query.report_name +
                      '&created=' +
                      $route.query.created
                    "
                  >
                    <v-icon color="primary">mdi-help</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="overline"
                  >Full screen
                </v-list-item-title>
                <v-list-action>
                  <v-btn icon @click="fullScreen">
                    <v-icon color="primary">mdi-fullscreen</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="overline">Refresh </v-list-item-title>
                <v-list-action>
                  <v-btn icon @click="refreshReport">
                    <v-icon color="primary">mdi-refresh</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="overline"
                  >Export Page
                </v-list-item-title>
                <v-list-action>
                  <v-btn icon @click="printReport">
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-btn>
                </v-list-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="overline"
                  >Export Report
                </v-list-item-title>
                <v-list-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs"
                  v-on="on" icon @click="dialog = true">
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>The exported report will be available from your exports section shortly, please check there for the export's status</span>
              </v-tooltip>
                </v-list-action>
              </v-list-item>
              <v-divider v-if="refreshHistory.length > 0"></v-divider>
              <v-item-group v-if="refreshHistory.length > 0">
                <v-list-item>
                  <v-list-item-title>Refresh History</v-list-item-title>
                </v-list-item>
              </v-item-group>
              <v-virtual-scroll
                v-if="refreshHistory.length > 0"
                bench="5"
                :items="refreshHistory"
                height="175"
                item-height="64"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item" two-line>
                    <v-list-item-content>
                      <v-list-item-title
                        :class="item.color + '--text'"
                        class="overline"
                        >{{ item.status }}</v-list-item-title
                      >
                      <v-list-item-subtitle lass="overline">{{
                        item.end
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon :color="item.color" small>
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>

    <!-- Export Report -->

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>Export Report
          <v-spacer></v-spacer>
          <v-btn
              @click="dialog = false; exportName = $route.query.report_name"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title>
        <v-text-field v-model="exportName" label="Name your export" class="mr-6 ml-6"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="startExport">Start Export</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import apiService from "../services/services.js";
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      reportname: null,
      q_and_a: this.$store.getters.isQna,
      pbitoken: null,
      size: null,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      created: null,
      refreshHistory: [],
      isLoading: true,
      reportPages: [],
      pageNumber: 0,
      currentPageName: null,
      exportName: this.$route.query.report_name,
      item:null,
    };
  },

  created() {
    analytics.page("Report", {
      "Report-Name": this.$route.query.report_name,
    });

    // CHECK if uses is allowed to export o

  },
  mounted() {
    this.reportname = this.$route.query.report_name;
    this.created = this.$route.query.created;

    apiService
      .getPBITokenReport(this.$route.query.report_id)
      .then((response) => {
        var models = window["powerbi-client"].models;
        var embedConfiguration = {
          type: "report",
          id: this.$route.query.report_id,
          embedUrl:
            "https://app.powerbi.com/reportEmbed?reportId=" +
            this.$route.query.report_id,
          tokenType: models.TokenType.Embed,
          accessToken: response.data.token,
          viewMode: models.ViewMode.View,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            background: 0,
            hideErrors: true,
            layoutType: 0,
            pageName: "ReportSection",
            visualSettings: {
              visualHeaders: [{
                settings: {
                  visible: this.$store.getters.getExportAccess,
                }
                // No selector - Hide visual header for all the visuals in the report
              }]
            }
          },
        };
        var $reportContainer = $("#reportContainer");
        var report = powerbi.embed($reportContainer.get(0), embedConfiguration);
        //this.isLoading = false;
        //report.off("rendered");

        report.on('rendered', () => {
          console.log('rendered');
          this.isLoading = false;
          report.off("rendered");
          });


        this.successMessage = "Succesfully Found Report";
        this.hasSuccess = true;

        return report;
      });


    apiService
      .getReportRefreshHistory(this.$route.query.dataset_id)
      .then((response) => {
        var refreshcount = response.data.data.length;
        this.refreshHistory = [];
        if (refreshcount > 0) {
          response.data.data.map((refreshHistoryData, index) => {

            if (refreshHistoryData.status == "Unknown") {
              this.refreshHistory.push({
                id: refreshHistoryData.id,
                type: refreshHistoryData.refreshType,
                status: "In progress",
                start: new Date(refreshHistoryData.startTime),
                end:
                  new Date(refreshHistoryData.startTime).getDate() +
                  "/" +
                  (new Date(refreshHistoryData.startTime).getMonth() + 1) +
                  "/" +
                  new Date(refreshHistoryData.startTime).getFullYear(),
                took: 60,
                color: this.getColor("In progress"),
                icon: this.getIcon("In progress"),
              });
            } else {
              this.refreshHistory.push({
                id: refreshHistoryData.id,
                type: refreshHistoryData.refreshType,
                status: refreshHistoryData.status,
                start: new Date(refreshHistoryData.startTime),
                end:
                new Date(refreshHistoryData.endTime).getDate() +
                "/" +
                (new Date(refreshHistoryData.endTime).getMonth() + 1) +
                "/" +
                new Date(refreshHistoryData.endTime).getFullYear(),
                took: 60,
                color: this.getColor(refreshHistoryData.status),
                icon: this.getIcon(refreshHistoryData.status),
              });
            }
            this.successMessage = "Succesfully Found Refresh History";
            this.hasSuccess = true;
            return index;
          });
        }
      })
      .catch((error) => {});
  },
  updated() {
    var element = $("#reportContainer")[0];
    var report = powerbi.get(element);
    report
      .getPages()
      .then((pages) => {

        function checkHidden(item){

          return (item.displayName.indexOf("HIDDENPAGE") === -1)
        }

        let cleanedPages = pages.filter(checkHidden)
        this.reportPages = cleanedPages;
      })
      .catch((error) => {
        console.log(error);
        location.reload();
        //this.getPages();
       // this.hasError = true;
       // this.errorMessage = "Couldn't load pages please try again.";
      });

    this.currentPageName = this.reportPages[this.pageNumber].displayName;
  },
  computed: {
    /*items() {
      if(typeof this.refreshHistory == undefined){
        return []
      } else {
        return this.refreshHistory;
      }

    },*/
  },
  methods: {
    getPages(){
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report
        .getPages()
        .then((pages) => {

          function checkHidden(item){

            return (item.displayName.indexOf("HIDDENPAGE") === -1)
          }

          let cleanedPages = pages.filter(checkHidden)
          this.reportPages = cleanedPages;
        })
        .catch((error) => {
          console.log(error);
         // this.hasError = true;
         // this.errorMessage = "Couldn't load pages please try again.";
        });

      this.currentPageName = this.reportPages[this.pageNumber].displayName;

    },
    getColor(resStatus) {
      if (resStatus === "Completed") {
        return "success";
      } else if (resStatus === "In progress")  {
        return "warning";
      } else {
        return "error";
      }
    },
    getIcon(resStatus) {
      if (resStatus === "Completed") {
        return "mdi-check";
      } else if (resStatus === "In progress") {
        return "mdi-autorenew";
      } else {
        return "mdi-close";
      }
    },
    isLoaded(value) {
      this.isLoading = value;
    },
    fullScreen() {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report.fullscreen();
      analytics.track("Fullscreen Report", {
        "Report-Name": this.$route.query.report_name,
      });
    },
    printReport() {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report.print();

      this.trackPrint(this.$route.query.report_name);
    },
    async exportReport(){
      console.log('Export Called')
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);

      try {
        const capturedBookmark = await report.bookmarksManager.capture();
        let log = "Captured bookmark state: " + capturedBookmark.state;
        console.log(log);
        axios.post('https://prod-13.uksouth.logic.azure.com:443/workflows/9c8f7850ae7b4df3a471b969d47cccb7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AlpIIYbnJc1y5KIdD0-2YXApaGguw-lqzbsoGDYNFvk',{
          "reportId": this.$route.query.report_id,
          "reportName": "export.pdf",
          "sender": this.$store.getters.getCompanyEmail,
          "token": this.$store.getters.getToken,
          "to_email": this.$store.getters.getEmail,
          "bookmarkState": capturedBookmark.state
          })
        }
        catch (error) {
          console.log(error);
        }


    },
    async startExport(){


      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);

      try {
        const capturedBookmark = await report.bookmarksManager.capture();
        let log = "Captured bookmark state: " + capturedBookmark.state;
        console.log(log);
          apiService.startExport(this.$store.getters.getUserId, this.exportName, capturedBookmark.state, this.$route.query.report_id )
            .then(response => {
              console.log(response)
              this.successMessage = "Succesfully started export";
              this.hasSuccess = true;
              this.dialog = false;
              this.exportName = this.$route.query.report_name;
            })
            .catch(err => {
              console.log(err)
               this.errorMessage = "Couldn't export report, please try again.";
               this.hasError = true;
            })
        }
        catch (error) {
          console.log(error);
        }
    },
    trackPrint(report) {
      analytics.track("Print Report", { "Report-Name": report });
    },
    close() {
      this.$router.push({ path: "/reports" });
    },
    refreshReport() {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      report.reload();
      analytics.track("Refresh Report", {
        "Report-Name": this.$route.query.report_name,
      });
      return (this.pageNumber = 0);
    },
    nextPage() {
      var newPage = this.pageNumber + 1;
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      const page = report.page(this.reportPages[newPage].name);
      page.setActive();

      this.pageNumber = newPage;
      analytics.track("Go To Report Page", {
        "Report-Name": this.$route.query.report_name,
        Page: newPage,
      });
    },
    previousPage() {
      var newPage = this.pageNumber - 1;
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      const page = report.page(this.reportPages[newPage].name);
      page.setActive();

      this.pageNumber = newPage;
      analytics.track("Go To Report Page", {
        "Report-Name": this.$route.query.report_name,
        Page: newPage,
      });
    },
    goToPage(pageName) {
      var element = $("#reportContainer")[0];
      var report = powerbi.get(element);
      const page = report.page(pageName);
      page.setActive();

      var newPage = this.reportPages
        .map(function (reportPage) {
          return reportPage.name;
        })
        .indexOf(pageName);
      this.pageNumber = newPage;
      analytics.track("Go To Report Page", {
        "Report-Name": this.$route.query.report_name,
        Page: newPage,
      });
    },
    qAndA() {
      this.dialog = true;
    },
  },
};
</script>

<style>
#reportContainer,
.reportContainer {
  background-color: transparent;
}
.desktop-view iframe,
.mobile-view iframe,
iframe {
  border: none;
  background-color: transparent;
}
</style>
