<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" fill-height>
        <v-card class="mx-auto">
          <v-list>
            <v-list-item>
              <v-list-item-title class="title">Edit User</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-stepper v-model="e6" vertical flat>
            <v-stepper-step editable :complete="e6 > 1" step="1">
              Name
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  placeholder="Add First Name Here"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  placeholder="Add Last Name Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 2"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 2" step="2">
              Email
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="email"
                  label="Email"
                  placeholder="Add First Name Here"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 3"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 3" step="3">
              Access Permissions
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-checkbox
                class="pl-5"
                v-model="isAdmin"
                label="Admin User"
              ></v-checkbox>
              <v-checkbox
                class="pl-5"
                v-model="qnaAccess"
                label="Q&A Access"
              ></v-checkbox>
              <v-checkbox
                class="pl-5"
                v-model="exportAccess"
                label="Data Export Access"
              ></v-checkbox>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 4"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable :complete="e6 > 2" step="4">
              Group
            </v-stepper-step>

            <v-stepper-content step="4">
              <v-col cols="12" sm="6" md="3">
                <v-switch label="Add a New Group" v-model="addNewGroup" ></v-switch>
                <v-select
                  v-show="!addNewGroup"
                  v-model="team"
                  :items="teams"
                  item-text="team"
                  item-value="team"
                  label="Group"
                  placeholder="Pick Group"
                  outlined
                ></v-select>

                <v-text-field
                v-show="addNewGroup"
                v-model="team"
                label="New Group"
                placeholder="Add New Group"
                outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="e6 = 5"> Next </v-btn>
              </v-col>
            </v-stepper-content>

            <v-stepper-step editable step="5"> Review & Submit </v-stepper-step>
            <v-stepper-content step="5">
              <v-card-text class="overline pb-1"
                >First Name: {{ firstName }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Last Name: {{ lastName }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Email: {{ email }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Group: {{ team }}</v-card-text
              >
              <v-card-text class="overline pb-1"
                >Company Admin:
                <v-btn
                  color="success"
                  v-if="isAdmin"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  v-if="!isAdmin"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-text class="overline pb-1"
                >Q&A Access:
                <v-btn
                  v-if="qnaAccess"
                  icon
                  class="align-self-start"
                  size="10"
                  color="success"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  v-if="!qnaAccess"
                  icon
                  class="align-self-start"
                  size="10"
                  color="error"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-text class="overline pb-1"
                >Data Export Access:
                <v-btn
                  color="success"
                  v-if="exportAccess"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  v-if="!exportAccess"
                  icon
                  class="align-self-start"
                  size="10"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-text>
              <v-col cols="12" sm="12" md="12" class="pb-5">
                <v-btn rounded color="primary" @click="editUser">
                  Submit
                </v-btn>
              </v-col>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasError" :timeout="3000" bottom color="error" right>
      Error: {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="hasSuccess"
      :timeout="3000"
      bottom
      color="success"
      right
    >
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiService from "../services/services.js";
export default {
  data() {
    return {
      e6: 1,
      firstName: this.$route.query.firstName,
      lastName: this.$route.query.lastName,
      email: this.$route.query.email,
      team: this.$route.query.team,
      teams: null,
      isAdmin: null,
      qnaAccess: null,
      exportAccess: null,
      hasError: false,
      errorMessage: null,
      hasSuccess: false,
      successMessage: null,
      addNewGroup: false,
    };
  },
  created() {
    analytics.page("Edit User");

    if(this.$route.query.isAdmin == 'true'){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    if(this.$route.query.qna == 'true'){
      this.qnaAccess = true;
    } else {
      this.qnaAccess = false;
    }

    if(this.$route.query.canExport == 'true'){
      this.exportAccess = true;
    } else {
      this.exportAccess = false;
    }

    // Get teams for the company
    apiService.getTeams(this.$store.getters.getCompanyId)
      .then(res => {
        this.teams = res.data;
      })
      .catch(err => {
        // Send a snackbar error
        this.errorMessage = 'Could not get groups';
        this.hasError = true;
      })


  },
  methods: {
    editUser() {
      apiService
        .editUser(
          this.firstName,
          this.lastName,
          this.email,
          this.$route.query.id,
          this.isAdmin,
          this.qnaAccess,
          this.team,
          this.exportAccess
        )
        .then((response) => {
          analytics.track("Edit User");
          this.$router.push({ path: "/Admin" });
        })
        .catch((error) => {
          this.errorMessage = error.response.data.error;
          this.hasError = true;
        });
    },
  },
};
</script>
